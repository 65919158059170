<template>

    <div class="wfp-token-authenticator">
        <div>
            <label>Username</label>
            <input disabled="!useUsernamePassword" class="input" type="text" v-model="username" />
        </div>

        <div>
            <label>Password</label>
            <input disabled="!useUsernamePassword" class="input" type="password" v-model="password" />
        </div>

        <label for="auth-token-input">Auth token (Base64)<span class="mark">:</span></label>
        <input :disabled="useUsernamePassword" type="password" class="input" v-model="authData.token"/>

        <div class="help-wrapper">
            <p class="help">
                This endpoint requires authentication via the base64 token or username/password 
                a pre-created token in a similar format to <code>abc1d23e-1abc-1234-12a3-12a3b4567cde</code>
            </p>
        </div>
    </div>

</template>

<script>

// function calculateToken(username, password) {
//     return btoa(`${username}:${password}`)
// }


export default {
    name: 'WfpTokenAuthenticatorUi',
    props: {
        authData: Object,
    },
    data() {
        return {
        username: "",
        password: "",
        };
    },

    watch: {
        // username(newName, old) {
        //     this.authData.username = newName;
        //     this.authData.password = this.password;
        //     this.authData.token = calculateToken(newName, this.password);
        // }
    },

    computed: {
        useUsernamePassword() { return Boolean(this.username) || Boolean(this.password) },
    }
}
</script>
