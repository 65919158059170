import $ from "jquery";
// A wrapper around jquery that creates a Promise
export default function makeJsonRequest(ajaxOptions) {
    return new Promise((resolve, reject) => {
        $.ajax(ajaxOptions).done(data => {
            // console.log("RESPONSE data:", data);
            resolve(data);
        }).fail((xhr, textErr, err) => reject(`XHR error while loading '${ajaxOptions.url}': ${textErr} ${err}`));
    })
}
