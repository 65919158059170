/* eslint-disable */
import makeJsonRequest from './utils/makeJsonRequest'
import $ from "jquery";

//////////////////////////////////////////////////////////////////////////
//


//////////////////////////////////////////////////////////////////////////
//

class ExternalApi {

    // baseUrl: The base URL of the API
    constructor(baseUrl, authenticator) {
        this.baseUrl = baseUrl;
        this.authenticator = authenticator;
    }

    getDataUrl(params) {
        return `${this.baseUrl}`;
    }

    makeRequest(ajaxParams) {
        return this.authenticator.prepareRequest({
            dataType: "json",
            timeout: (10 * 60 * 1000),
            ...ajaxParams,
        }).then(makeJsonRequest);
    }

    // gets a single page of data
    getPage(params) {
        return this.makeRequest({
            url: this.getDataUrl(params),
            method: "GET",
            data: params,
        });
    }


}

//////////////////////////////////////////////////////////////////////////



class Paginator {
    constructor(apiCaller, params, paginationHandler) {
        this.apiCaller = apiCaller;
        this.params = params;
        this.lastScrollId = undefined;

        this.paginationHandler = paginationHandler;

        // set to true to log each page load on the remote server
        this.remoteLoggingEnabled = false;

        this.pageIndex = 0;
    }


    // executes the provided function `fn` on all elements extracted
    //
    // Returns a promise that resolves after completition.
    forEach({ row, batch, requestParams = {} }) {
        // let max = 0;

        let getPage = () => {
            // add scrollId to the params of getPage
            let pageParams = Object.assign({}, this.params, requestParams);

            // add the params from the pagination handler
            let newParams = this.paginationHandler.addToRequest(pageParams);
            console.log("new params with pagination: ", newParams);

            this.logIfNeeded(`Starting new page #${this.pageIndex} with params: ${JSON.stringify(newParams)}`);
            return this.apiCaller.getPage(newParams)
                .then(data => {
                    this.logIfNeeded(`Page #${this.pageIndex} HTTP done`);
                    let rows = this.paginationHandler.getRows(data);
                    rows.forEach(v => row(v));
                    return { data, rows };
                })
                .then(({ data, rows }) => {
                    // callback after batch is done
                    batch();

                    // log the fact the page is done
                    this.logIfNeeded(`Finished page #${this.pageIndex}`);
                    this.pageIndex++;

                    console.log("Batch done, checking if all is done...");
                    // stop execution if we dont have any right now
                    if (rows.length === 0) return;

                    // stop execution if we dont have any more
                    if (!this.paginationHandler.next(data))
                        return;

                    console.log("Getting next page...");
                    // recur here
                    return getPage();
                });
        };

        return getPage(undefined);
    }


    // Process each row by `row` function and add to the tableau table `table`
    //
    // Returns a promise that resolves after completition.
    processAndAddToTableau(table, requestParams, rowFn = x => x) {
        let tableData = [];

        return this.forEach({
            requestParams,

            row: row => {
                // console.log("Got row: ", row);
                tableData.push(...rowFn(row));
            },
            batch: () => {
                // insert on every batch
                // console.log("Inserting tableData: ", tableData);
                table.appendRows(tableData);
                tableData = [];
            }
        });
    }


    // Logs the message if logging is enabled
    logIfNeeded(message) {
        if (!this.remoteLoggingEnabled) return;

        console.log("Sending remote log message:", message);
        logMessageOnServer(message);

    }
}

//////////////////////////////////////////////////////////////////////////


function logMessageOnServer(message) {
    // we send the request, and simply don't care about the result
    $.ajax({
        url: "/debug/log",
        method: "PUT",
        data: { msg: message },
    }).done(data => {
        // console.log("RESPONSE data:", data);
        // resolve(data);
    }).fail(err => {
        // console.log("Log error: ", err)
    });
}



export default {
    ExternalApi,
    Paginator,

    logMessageOnServer,

};