<template>
    <div>
        <h3>
            <span class="familyName">{{ endpoinFamilytName }} / </span>
            {{ endpointName }}
        </h3>

        <div class="endpointPath">
            <code>
                <span class="server">{{ endpointPath.server }}</span>
                <span class="path">{{ endpointPath.path }}</span>
            </code>
        </div>

        <div class="parameters-wrapper">
            <h4 v-if="hasParameters">Parameters</h4>
            <parameter-input v-for="parameter in endpoint.parameters" :key="parameter.id" :parameter="parameter" :parametersData="endpointData.parameterValues">
            </parameter-input>
        </div>

        <div class="authentication-wrapper">
            <h4>Authentication</h4>
            <component :is="authenticator.uiComponent()" :authData="endpointData.credentials"></component>
        </div>
    </div>
</template>

<script>
import ParameterInput from './ParameterInput'

import GoDocsAuthenticatorUi from '../authenticators/GoDocsAuthenticatorUi.vue'
import WfpTokenAuthenticatorUi from '../authenticators/WfpTokenAuthenticatorUi'
import WfpRawTokenAuthenticatorUi from '../authenticators/WfpRawTokenAuthenticatorUi'

export default {
  name: 'EndpointUi',
  props: {
      // A full Endpoint definition object
    endpoint: Object,
    endpointData: Object,
  },
  components: {
      ParameterInput,

      GoDocsAuthenticatorUi,
      WfpTokenAuthenticatorUi,
      WfpRawTokenAuthenticatorUi,
  },

  data() {
      return {
          parametersData: {},
          authData: {},
      }
  },

  computed: {
    authenticator() { return this.endpoint.authenticator },

    hasParameters() { return this.endpoint.parameters.length > 0; },

    endpoinFamilytName() { return this.endpoint.metadata.apiFamily; },
    endpointName() { return this.endpoint.metadata.name; },

    endpointPath() {
        let parts = this.endpoint.url.match(/^(https?:\/\/[^/]+\/)(.*?)$/);
        return {
            server: parts[1],
            path: parts[2],
        }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .familyName { opacity: 0.7; font-weight: normal; }

    h4 { opacity: 0.6; text-align: right; margin-bottom: 0.4em; font-weight: normal; font-size: 0.9em; border-bottom: 0.1em solid #ddd; }

    .endpointPath { background: rgb(239, 239, 239); padding:0.3em 0.5em; margin-top: -1em; }
    .endpointPath .server { opacity: 0.5; }
</style>
