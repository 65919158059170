<template>
  <div id="app">
    <div class="logo">
      <img src="wfp-logo-standard-blue-en.png" alt="WFP logo" />
    </div>

    <div class="debug-data" v-if="debugEnabled">
      {{ endpointData }}
    </div>

    <div class="redirectMessage" v-if="!isRunningInTableau">
      This Web Data Connector needs Tableau...
    </div>


    <div class="redirectMessage" v-if="noEndpoints && isRunningInTableau && !endpointsListLoadError">
      Redirecting for authentication...
    </div>

    <!-- <div class="redirectMessage error" v-if="endpointsListLoadError">
      Error while loading the WFP API Gateway Endpoints Definition list...
      <p>
        <small>
          {{ endpointsListLoadError }}
          </small>
      </p>
    </div> -->

    <div v-if="!noEndpoints" :class="{ endpointSelectorWrap: true, selected: hasEndpointSelected, notSelected: !hasEndpointSelected }">

      <!-- Intro page -->
      <div v-if="!hasEndpointSelected">
        <select v-model="endpointFamily" v-if="!hasEndpointSelected">
          <option :value="null">-- Select an API family --</option>
          <option v-for="e, name in endpointFamilies" :key="name" :value="e" :disabled="hasEndpointSelected">{{ name }}</option>
        </select>

        <select v-model="endpoint" v-if="hasEndpointFamilySelected">
          <option :value="null">-- Select an API endpoint --</option>
          <option v-for="e in endpointFamily" :key="endpointKeyOf(e)" :value="e">{{ e.metadata.name }} {{e.metadata.version }}</option>
        </select>
      </div>


      <!-- On-page stuff -->
      <div v-if="hasEndpointSelected">
        <select v-model="endpoint">
          <option :value="null">-- Select an API endpoint --</option>
          <option v-for="e in allEndpoints" :key="endpointKeyOf(e)" :value="e">{{ e.metadata.apiFamily }} / {{ e.metadata.name }} {{ e.metadata.version }}</option>
        </select>

        <select v-model="endpoint">
          <option v-for="e in endpointVersions" :key="e.metadata.version" :value="e">{{ e.metadata.version }}</option>
        </select>
      </div>

      <tag-list :tags="endpointTags"></tag-list>

      <!-- No endpoint selected -->
      <div class="no-endpoint-selected" v-if="!hasEndpointSelected">
        Please select an API endpoint from the list...
      </div>

    </div>

    <!-- Everything endpoint related -->
    <div class="endpoint-ui-wrapper" v-if="hasEndpointSelected">
      <endpoint-ui :endpoint="endpoint" :endpointData="endpointData"></endpoint-ui>

      <div class="submitWrapper">
        <button id="submitButton" @click="submitClicked" class="btn">Get the data</button>
      </div>
    </div>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Endpoints from './_generated/Endpoints'
import EndpointDb from './EndpointDb'
import EndpointUi from './components/EndpointUi'
import * as wdc from './wdc'
import TagList from './components/TagList.vue'

// let endpointsDb = new EndpointDb(Endpoints.endpoints);
let endpointsDb = new EndpointDb([]);


export default {
  name: 'App',
  components: {
    // HelloWorld
    EndpointUi,
    TagList,
  },

  methods: {
    endpointKeyOf(e) { return e.metadata.name + '||' + e.metadata.version; },

    // updates the endpoints list and the endpoints db data with the provided list
    updateEndpointsList(newEndpointsList) {
      this.endpoints = newEndpointsList;
      this.endpointsDb = new EndpointDb(newEndpointsList);
    },

    // updates the App state from the stored connectionData and password from Tableau
    updateFromConnectionDataAndPassword({connectionData, password}) {
      // if no connection data w/ api info  is present, skip setting back the state
      if (typeof connectionData.api !== 'string') {
        return;
      }

      console.log("Connection data:", connectionData)
      let endpoint = wdc.getApiDefinition(this.endpoints, connectionData.api);

      if (typeof endpoint !== 'object') {
          throw new Error("Cannot find API for key: '" + connectionData.api + "'");
      }

      this.endpoint = endpoint;
      this.endpointFamily = this.endpointsDb.getAllFamilies()[connectionData.metadata.apiFamily];

      this.endpointData.parameterValues = connectionData.data;
      this.endpointData.credentials = password;
    },

    missingEndpointsList(err) {
      this.updateEndpointsList([]);
      this.endpointsListLoadError = err;
    },

    // handle submission
    submitClicked() {
      wdc.setTargetApi(this.endpoint);
      wdc.submit(this.endpointData.parameterValues, this.endpointData.credentials)
        .then((result) => {
          console.log("Result of click:", result);
        });
    },
  },

  data() {

    // subscribe for a notification on WDC boot when tableau.password will be available
    wdc.onConnectorLoaded(() => {
      this.isRunningInTableau = true;
      // when tableau.password is ready attempt
      wdc.getEndpointDefinitions().then(defs => {

        // check if we actually have endpoints
        if (Array.isArray(defs) && defs.length > 0) {
          this.updateEndpointsList(defs);
          this.updateFromConnectionDataAndPassword(wdc.getConnectionDataAndPassword())
        } else {
          // if not show the user
          this.missingEndpointsList(new Date());
        }
      })
    })


    return {
      debugEnabled: false,
      isRunningInTableau: false,

      endpoint: null,
      // endpoints: Endpoints.endpoints,
      endpoints: [],

      endpointsDb: endpointsDb,

      endpointFamily: null,

      // errors while loading the endpoints list
      endpointsListLoadError: null,

      endpointData: {
        parameterValues: {},
        credentials: {},
      },
    }
  },

  computed: {
    // Are there any endpoints selected?
    hasEndpointSelected() { return typeof this.endpoint === 'object' && this.endpoint !== null; },
    hasEndpointFamilySelected() { return typeof this.endpointFamily === 'object' && this.endpointFamily !== null; },

    allEndpoints() { return this.endpointsDb.getAllEndpoints(); },

    // A map of endpoint family name => endpoint list
    endpointFamilies() { return this.endpointsDb.getAllFamilies(); },

    // All versions of the currently selected endpoint
    endpointVersions() {
      if (!this.hasEndpointSelected) return [];

      let id = this.endpoint.id;
      let {apiFamily} = this.endpoint.metadata;
      return this.endpointsDb.getEndpointVersionsForId(apiFamily, id);
    },

    // Get a list of tags that are in the metadata
    endpointTags() {
      if (!this.hasEndpointSelected) return [];

      return this.endpoint.metadata.tags;
    },

    // True if there are no endpoint definitions loaded
    noEndpoints() {
      return this.endpoints.length === 0;
    },

  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;

  /* margin-top: 60px; */

}

.logo { position: absolute; top: 10px; left: 10px; }

label { display: block; font-weight: bold; }
label .mark { opacity: 0.7; }
.input { font-weight: bold; display: block; margin-top: 0.5em; padding: 0.2em 0.5em; }

select.input { }
input.input { width:95%; }

.valid { }
.valid .input { }

.notValid { }
.notValid .input { border-color: red; color: red; }

.help-wrapper { margin: 0.5em 0 1em 0; }
.help { font-size: 0.85em; opacity: 0.8; margin: 0.5em 0; border-left: 5px solid #ccc; padding-left: 1em; }
.extra-help { font-style: italic; }


.endpointSelectorWrap { text-align: center; transition: 0.2s; padding: 0 8px; }
.endpointSelectorWrap.selected { text-align: right; min-height: 90px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }
.endpointSelectorWrap.notSelected { padding-top: 3em; }

.endpointSelectorWrap select { transition:  0.2s; padding: 0.5em 0.4em; }
.endpointSelectorWrap.selected select { color: #0A6EB4; font-weight: bold; border: 0.2em solid #0A6EB4; margin-top:7px; }
.endpointSelectorWrap.notSelected select { font-size: 1em; margin: 2em;  }

.endpoint-ui-wrapper { width: 35em; margin: 0 auto; }


.submitWrapper { text-align:right; }
#submitButton {font-size: 1em; padding: 0.3em 0.5em; font-weight: bold;}

.redirectMessage { margin: 70px auto; font-size: 1.8em; text-align: center; }

.redirectMessage.error {
  color: rgb(255, 64, 0);
}

</style>
