<template>
    <div :class="{parameterInput: true, inputRow: true, valid: isValid, notValid: !isValid}">

        <label>
            {{ parameter.alias }}

            <span class="optional-label mark-label" v-if="!isRequired">(optional)</span>
            <span class="required-label mark-label" v-if="isRequired">(required)</span>

            <span class="mark">:</span>
        </label>

        <!-- select -->
        <select v-if="isSelect" v-model="parametersData[parameter.id]" class="input">
            <option v-for="value in parameter.input.options" :key="value.value" :value="value.value">{{ value.label }}</option>
        </select>

        <!-- text input -->
        <input v-if="isText" v-model="parametersData[parameter.id]" class="input" />

        <div class="help-wrapper">
            <p class="help">{{ parameter.help }}</p>
            <p class="help extra-help">{{ parameter.extraHelp }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ParameterInput",
    props: {
        parameter: Object,
        parametersData: Object,
    },

    computed: {
        parameterValue() { return this.parametersData[this.parameter.id]; },

        isSelect() { return this.parameter.input.kind === 'option'; },
        isText() { return this.parameter.input.kind === 'text'; },

        isRequired() { return Boolean(this.parameter.required); },

        isValid() { 
            // skip if no validate function
            if (typeof this.parameter.validate !== 'function') return true;

            return this.parameter.validate(this.parameterValue); 
        }
    }
}
</script>

<style scoped>

.parameterInput { }

.mark-label { font-size: 0.75em; }
.optional-label { opacity: 0.7; }
.required-label { opacity: 1; }


</style>