
// ENDPOINT METADATA ACCESS
// ------------------------

// Returns the API Family name for an endpoint
function getApiFamilyName(endpoint) {
    return endpoint.metadata.apiFamily.toUpperCase();
}

// Returns the API Family name for an endpoint
function getApiId(endpoint) {
    return endpoint.id.toUpperCase();
}

function getApiVersion(endpoint) {
    return endpoint.metadata.version.toUpperCase();
}

// A compare function for sorting endpoints by version (from most recent to oldest)
function compareVersions(a, b) {
    let versionA = getApiVersion(a);
    let versionB = getApiVersion(b);

    return versionA > versionB ? -1 : 1;
}

// ENDPOINT DB
// -----------

export default class EndpointDb {
    constructor(endpointList) {
        this.endpointList = endpointList;
    }

    // returns a list of API families from the endpoint list
    getAllFamilies() {

        return this.endpointList.reduce((memo, e) => {
            let familyName = getApiFamilyName(e);
            let existingFamily = memo[familyName];
            if (Array.isArray(existingFamily)) {
                existingFamily.push(e);
                // sort the members
                existingFamily.sort(compareVersions);
            } else {
                existingFamily = [e];
            }
            memo[familyName] = existingFamily;
            return memo;
        }, {})

    }

    // Returns all endpoints that are in the DB
    getAllEndpoints() {
        return this.endpointList;
    }

    // Returns all versions of an endpoint by family and id
    getEndpointVersionsForId(familyName, id) {
        return this.endpointList.filter(e => {
            return getApiFamilyName(e) === familyName.toUpperCase() && getApiId(e) === id.toUpperCase();
        }).sort(compareVersions)
    }

}

