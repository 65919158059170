import RemoteApi from './remote-api'
// import Endpoints from './_generated/Endpoints';
/* global tableau */

var connectorDefinition = tableau.makeConnector();
let externalApiHandler = null;

// A global that we'll use to pass the target API to the WDC handlers
let targetApi = null;

export function setTargetApi(api) {
    targetApi = api;
}

////////////////////////////////////////
// API identification helpers

// Return the "connectionName" (as displayed in Tableau) for the API endpoint
function getEndpointConnectionName(api) {
    // if the user specifies a `connectionName` use that
    if (api.metadata.connectionName) return api.metadata.connectionName;

    // If we have a name (we should) return that with some decoration
    if (api.metadata.name) return `WDC: ${api.metadata.name}`;

    // have a fallback
    return "WFP WDC"
}

////////////////////////////////////////
// API endpoint identification

// Returns a string that can be used as a key to uniquely identify the API endpoint (including versions)
function generateApiId(api) {
    return `${api.id}||${api.metadata.version}`
}

// Returns true if the api matches the provided API key
function apiMatches(apiId, api) {
    return generateApiId(api) === apiId;
}


// returns the decoded connectionData and password JSON objects from tableau if available
export function getConnectionDataAndPassword() {
    // no tableau => no data
    if (typeof tableau === 'undefined') {
        return {
            connectionData: {},
            password: {},
        };
    }

    // decode data
    let {connectionData, password} = tableau;
    // prepare output
    let output = {
        connectionData: {},
        password: {},
    }

    // decode connectiondata and password if there is data there
    if (typeof connectionData === 'string' && connectionData.length > 0) {
        output.connectionData = JSON.parse(connectionData);
    }

    if (typeof password === 'string' && password.length > 0) {
        output.password = JSON.parse(password);
    }

    return output;
}

export function getApiDefinition(endpointDefinitions, apiKey) {
    for (let endpoint of endpointDefinitions) {
        if (apiMatches(apiKey, endpoint)) {
            return endpoint;
        }
    }
    return null;
}

////////////////////////////////////////
// Find the current target API from the connectionData of Tableau

function getCurrentApiAndConnectionData(endpointDefinitions) {

    let connectionData = JSON.parse(tableau.connectionData)
    let apiKey = connectionData.api;

    if (typeof apiKey !== 'string') {
        throw new Error("Cannot find which API to use in tableau.connectionData: " + connectionData);
    }

    let apiDefinition;
    for (let endpoint of endpointDefinitions) {
        if (apiMatches(apiKey, endpoint)) {
            apiDefinition = endpoint;
            break;
        }
    }

    if (typeof apiDefinition !== 'object') {
        throw new Error("Cannot find API for key: '" + apiKey + "'");
    }

    return {
        api: apiDefinition,
        connectionData: connectionData.data,
        credentials: JSON.parse(tableau.password),
    };


}

// the key used in tableau.password for storing the JWT token
const ENDPOINTS_JWT_KEY = "endpoints_jwt";

// loads the list of endpoints if have
function haveJwtToken() {
    // check if we have an existing token in the credentials
    let credentials = tableau.password !== '' ? JSON.parse(tableau.password) : {};

    // console.log("CREDENTIALS:", credentials)

    // if we have the token return it
    if (typeof credentials[ENDPOINTS_JWT_KEY] === 'string' && credentials[ENDPOINTS_JWT_KEY] !== "") {
        return {
            have_token: true,
            token: credentials[ENDPOINTS_JWT_KEY]
        };
    }

    // check if we have the token as URL parameter
    let url = new URL(window.location);
    let new_jwt_token = url.searchParams.get("new_jwt_token");

    if (typeof new_jwt_token === 'string' && new_jwt_token !== "") {
        return {
            have_token: true,
            token: new_jwt_token,
        }
    }

    // we do not have the token
    return {
        have_token: false,
    }
}


// A global singleton for the endpoints definitions
let endpointsDefinitionPromise;

// The URL of the endpoints definition file to load if possible
const ENDPOINTS_DEFINITION_URL =  "/endpoints.umd.js"

// where should the browser be redirected if no token is present
const AUTH_REDIRECT_URL = "/auth/signin";

// Start loading the endpoint definitions if needed
export function getEndpointDefinitions() {
    // if we already started then ignore and return existing promise
    if (endpointsDefinitionPromise) {
        return endpointsDefinitionPromise;
    }

    // check if we have the token

    let haveTokenResult = haveJwtToken();
    console.log("HAVE TOKEN: ", haveTokenResult)
    if (!haveTokenResult.have_token) {
        window.location.href = AUTH_REDIRECT_URL;
        return Promise.resolve([]);
    }

    let jwt_token = haveTokenResult.token;

    // handle loading via a <script> tag
    endpointsDefinitionPromise = new Promise((resolve, reject) => {


        let sc = document.createElement("script");
        document.head.appendChild(sc);

        // create the full procted URL, and use a GET request w/ URL parameters
        let fullProtectedUrl = `${ENDPOINTS_DEFINITION_URL}?wdc_auth=${jwt_token}`;

        console.log("Using full protected URL:", fullProtectedUrl);

        // The event handler should be assigned AFTER the tag is added to the document (but before SRC is assigned)
        sc.onload = () => {
            // check if the endpoint definitions themselves are loaded
            if (typeof global.endpoints === 'undefined' || typeof global.endpoints.default === 'undefined') {
                console.log("Failed to load endpoint definitions -- cannot find 'endpoints' to import.")
                resolve([]);
                return;
            }

            let endpointsList = global.endpoints.default.endpoints
            resolve(endpointsList);
        };

        sc.onerror = (oError) => {
            reject(new URIError(`The script ${oError.target.src} didn't load correctly.`))
        };

        sc.setAttribute("src", fullProtectedUrl);
        sc.setAttribute("type", "text/javascript");

    });

    return endpointsDefinitionPromise;
}


// Callbacks for the Application
// -----------------------------

const onConnectorLoadCallbacks = [];

export function onConnectorLoaded(callback) {
    onConnectorLoadCallbacks.push(callback);
}

function runOnConnectorLoaded(...args) {
    onConnectorLoadCallbacks.forEach(fn => {
        fn(...args)
    })
}

//////////////////////////////////////////////////////////////////////////


// Init function for connector, called during every phase
connectorDefinition.init = function (initCallback) {
    // We set the Auth type here (we can use "custom" for fully customized auth data input).
    //
    // Both custom & basic auth seems to be broken in Desktop 2021.4 so we are using the
    // interactive phase to store the password (base64), but we're storing it in tableau.password
    // let needsAuth = (targetApi.auth !== false);
    let needsAuth = true;

    if (needsAuth) {
        tableau.authType = tableau.authTypeEnum.custom;
    }

    // parameterCollection = new ApiParameterCollection(exampleApi.parameters);

    // signal that we have the connector base information (like password, connectiondata, etc loaded)
    runOnConnectorLoaded();

    console.log("Tableu password", tableau.password)

    getEndpointDefinitions().then(defs => {
        console.log("Got endpoint definitions: ", defs)


        // If we are not in the data gathering phase, we want to store the token
        // This allows us to access the token in the data gathering phase
        if (tableau.phase == tableau.phaseEnum.interactivePhase) {
            /* eslint-disable-no-empty */
        }

        // If we are in the auth phase we only want to show the UI needed for auth
        // NOTE: this is borked in TD 2021.1 while testing
        if (tableau.phase == tableau.phaseEnum.authPhase) {
            // ...
        }

        if (tableau.phase == tableau.phaseEnum.gatherDataPhase) {

            // check for the password's presence here
            if (needsAuth && (typeof tableau.password !== 'string' || tableau.password.length === 0)) {
                tableau.abortForAuth();
                initCallback();
                return;
            }

            // If the API that WDC is using has an endpoint that checks
            // the validity of an access token, that could be used here.
            // Then the WDC can call tableau.abortForAuth if that access token
            // is invalid.
            // tableau.abortForAuth();
            let { api, credentials } = getCurrentApiAndConnectionData(defs);

            externalApiHandler = new RemoteApi.ExternalApi(
                api.url,
                api.authenticator.constructor(credentials),
            );
        }

        if (tableau.phase == tableau.phaseEnum.interactivePhase) {
            // let inputsHtml = parameterCollection.buildInputHtml();
            // console.log("inputs HTML:", inputsHtml)
            // $('#inputs-container').html(inputsHtml);
        }


        initCallback();
    });
}

//////////////////////////////////////////////////////////////////////////

// Do the extra checks that the Simulator does not do for schemas for easier debugging
function validateSchema(tableSchema) {
    // the name can only contain letters, numbers and underscores
    function isValidId(id) {
        return !/[^A-Za-z0-9_]/.test(id)
    }

    // collect all Ids and check them
    let allIds = tableSchema.columns.map(col => col.id).concat([tableSchema.id])
    if (allIds.some(id => !isValidId(id))) {
        console.error("ALL IDS:", allIds);
        console.error("Bad ids:", allIds.filter(id => !isValidId(id)));
        throw new Error("The schema contains invalid ids!")
    }


}

// This is where we pass the schema to Tableau
connectorDefinition.getSchema = function (schemaCallback) {
    getEndpointDefinitions().then(defs => {
        let { api, connectionData } = getCurrentApiAndConnectionData(defs);

        Promise.resolve(api.getSchema(connectionData, externalApiHandler))
            .then(tableSchema => {
                tableau.log("Using schema:", JSON.stringify(tableSchema, null, 2));

                // check the schema for debugging
                validateSchema(tableSchema);

                // we can add multiple tables
                schemaCallback([tableSchema]);
            })
            .catch(err => {
                // error while processing -- log it, then abort
                logInternalErrorAndAbort(err);
            });
    });
};

//////////////////////////////////////////////////////////////////////////

// This is where we load the data for a specific table (after the tables have been defined)
connectorDefinition.getData = function (table, doneCallback) {
    getEndpointDefinitions().then(defs => {
        let { api, connectionData } = getCurrentApiAndConnectionData(defs);

        let paginator = new RemoteApi.Paginator(externalApiHandler, {}, api.paginator());

        // let the API build the URL args
        let requestParams = api.buildUrlParameters(connectionData);

        paginator.processAndAddToTableau(table, requestParams, api.processRow)
            .then(() => { doneCallback(); })
            .catch(err => {
                // error while processing -- log it, then abort
                logInternalErrorAndAbort(err);
            });

    });
};


function logInternalErrorAndAbort(err) {
    console.log("ERROR:", err);

    let stackTop = err;
    if (typeof err === 'object' && typeof err.stack !== 'undefined') {
        stackTop = err.stack.split(/[\n\r]+/).slice(0, 2).join('\n').replace(/wdc_auth=[a-zA-Z0-9]+/, '');
    }

    // RemoteApi.logMessageOnServer(`WDC Internal error: ${stackTop}`);
    tableau.abortWithError(`WDC Internal ${stackTop}`)
}

//////////////////////////////////////////////////////////////////////////

tableau.registerConnector(connectorDefinition);

//////////////////////////////////////////////////////////////////////////

// Calls tableau.submit()
export function submit(parameterValues, credentials) {

    return targetApi.authenticator.constructor(credentials).hasValidCredentials()
        .then(isValidCred => {
            console.log("fetchNewToken", isValidCred)
            // Abort on error
            if (!isValidCred) return Promise.reject('Invalid credentials!');


            // start connection params validation later
            return validateConnectionParameters(parameterValues)
                .then(isValid => {
                    // show($parameterError, !isValid);
                    if (!isValid) return Promise.reject("Invalid parameters");


                    // save credentials and data
                    return saveCredentialsAndData(credentials, parameterValues);
                });
        })


    // Step 2: validate connectionData parameters -- prompt user if not valid (this allows API checks)
    function validateConnectionParameters(paramValues) {
        // for (let k of Object.keys(paramValues)) {

        // }
        console.log("validate: ", paramValues);
        // TODO: use the validated version for prod
        return Promise.resolve(true);
        // return Promise.resolve(parameterCollection.validateParameters(paramValues))
    }

    // Step 3: Save conncetion data and credentials

    function saveCredentialsAndData(credentials, parameterValues) {
        // the connection name and data is only updated during the interactive phase
        if (tableau.phase === tableau.phaseEnum.interactivePhase) {
            // setup the connection name and data
            saveConnectionDataAndTargetApi(targetApi, parameterValues);
        }

        saveConnectionCredentials(credentials);

        tableau.submit();

        return Promise.resolve(true);
    }


}



function saveConnectionDataAndTargetApi(api, connectionData) {
    tableau.connectionName = getEndpointConnectionName(api);
    tableau.connectionData = JSON.stringify({
        api: generateApiId(api),
        apiId: api.id,
        data: connectionData,
        metadata: api.metadata,
    });
}

function saveConnectionCredentials(credentials) {

    // update the JWT token
    let jwtToken = haveJwtToken();
    if (jwtToken.have_token) {
        Object.assign(credentials, { [ENDPOINTS_JWT_KEY]: jwtToken.token });
    }

    tableau.username = "token";
    tableau.password = JSON.stringify(credentials);
}
