
<template>
    <div class="tags">
        <ul>
            <li v-for="tag in tags" :key="tag.name + '__' + tag.value" :class="classesForTag(tag)">
                <span class="name">{{ tag.name }}</span>:
                <span class="value">{{ tag.value }}</span>
            </li>
        </ul>
    </div>

</template>

<script>

// Escape a string to be used as an HTML class name
function escapeString(s) {
    return s.toLowerCase().replace(/[^a-zA-Z0-9-]+/g, '-');
}

export default {
    name: "TagList",
    props: {
        tags: Array,
    },

    methods: {

        classesForTag(tag) {
            return {
                ['tag-' + escapeString(tag.name)]: true,
                ['value-' + escapeString(tag.value)]: true,
            };
        },
    },
}
</script>

<style scoped>
    .tags ul { list-style: none; }
    .tags ul li { display: inline-block; padding: 0.2em 0.5em; background: #ccc; border-radius: 0.5em; margin-left: 0.2em; font-size: 0.85em; } 
    .tags .name { opacity: 0.8; }
    .tags .value { font-weight: bold; }

    .tags .tag-visibility.value-public,
    .tags .tag-access-policy.value-open { background-color: rgb(104, 255, 134); }

    .tags .tag-visibility.value-internal { background-color: rgb(255, 225, 104); }

    .tags .tag-data-classification.value-official-use-only,
    .tags .tag-access-policy.value-restricted { background-color: rgb(255, 209, 57); }

</style>