<template>

    <div class="wfp-raw-token-authenticator">
        <div>
            <label for="auth-token-input">Access token <span class="mark">:</span></label>
            <input type="password" class="input" v-model="authData.token"/>
        </div>

        <div class="help-wrapper">
            <p class="help">
                This endpoint requires authentication via a pre-created token in a similar format to <code>abc1d23e-1abc-1234-12a3-12a3b4567cde</code>
            </p>
        </div>

        <div>
            <label for="auth-token-input">DSS Token <span class="mark">:</span></label>
            <input type="password" class="input" v-model="authData.xDssAuthorization"/>
        </div>

        <div class="help-wrapper">
            <p class="help">
                 The DSS token got from the consumer from Azure AD.
            </p>
        </div>
    </div>

</template>

<script>

export default {
    name: 'WfpRawTokenAuthenticatorUi',
    props: {
        authData: Object,
    },
}
</script>
